import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import HashMap from 'hashmap';
import { UiFacility } from '@/models/facility';
import { ViewOrderParams } from '@/store/types/modules';
import FacilitySignageRow from '@/components/organisms/FacilitySignageRow.vue';
import ArrowsIcon from '@/assets/images/arrows-v.svg';

const view = namespace('view');

@Component({
  components: { draggable, FacilitySignageRow, ArrowsIcon },
})
export default class FacilityBookingList extends Vue {
  @Prop({ type: String, default: '' }) private title!: string;

  @Prop({ type: Array }) private facilities!: UiFacility[];
  
  @Prop({ type: Boolean }) private animate!: boolean;

  @Prop({ type: Boolean }) private isCurrent!: boolean;

  @Prop({ type: String }) private animationDirection!: 'right' | 'left';
  
  @Prop({ type: Number }) private animationDelay!: number;

  @Prop({ type: Boolean }) private runningOnMobile!: boolean;

  @Prop({ type: String }) private building!: string;
  
  @Prop({ type: Boolean, default: false }) private publicAccess!: boolean;

  @view.Mutation('setViewOrderByBuildingAndFloor') setViewOrderByBuildingAndFloor!: (params: ViewOrderParams) => void;

  @view.Getter('getViewOrderByBuildingAndFloor') getViewOrderByBuildingAndFloor!: (building: string, floor: string) => string[];

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  @view.State('viewsOrder') viewsOrder!: string[];

  sortedFacilitiesIds: string[] = [];

  get headers(): Array<Record<string, any>> {
    return [
      { key: 'room', title: this.$gettext('Facility') },
      { key: 'status', title: this.$gettext('Status') },
      { key: 'current-event', title: this.$gettext('Current Event') },
      { key: 'next-event', title: this.$gettext('Next Event') },
    ];
  }

  get tableAnimationName(): string {
    return this.animate ? 'Table-animation' : 'Table-animation--fast';
  }

  get tableTitleAnimationName(): string {
    return this.animate ? 'Table__title-animation' : 'Table__title-animation--fast';
  }

  get displaySection(): boolean {
    return !this.isPublicDisplayView || this.isCurrent;
  }

  get viewOrderByFloor(): string[] {
    return this.getViewOrderByBuildingAndFloor(this.building, this.title);
  } 

  get draggableHandle(): string {
    return this.runningOnMobile ? '.Facility__drag' : '.Facility';
  } 

  get isDragDisabled(): boolean {
    return this.sortedFacilitiesIds.length === 1 || this.isPublicDisplayView;
  } 

  get facilitiesMap(): HashMap<string, UiFacility> {
    const facilitiesMap: HashMap<string, UiFacility>  = new HashMap();
    this.facilities.forEach((f: UiFacility) => {
      facilitiesMap.set(f.facility_id, f);
    });
    return facilitiesMap;
  }

  get facilitiesIds(): string[] {
    return this.facilities.map(item => {
      return item.facility_id;
    });
  }

  @Watch('facilities')
  refresh(): void {
    this.cleanFacilitiesOrder();
    this.changeOrderHandler();
  }

  created(): void {
    if (this.viewOrderByFloor.length === 0) {
      this.sortedFacilitiesIds = Array.from(this.facilitiesIds);
    } else {
      this.sortedFacilitiesIds = Array.from(this.viewOrderByFloor);
    }
  }

  cleanFacilitiesOrder(): void {
    this.facilitiesMap
      .keys()
      .filter(key => !this.sortedFacilitiesIds.includes(key))
      .forEach(key => this.sortedFacilitiesIds.push(key));

    this.sortedFacilitiesIds = this.sortedFacilitiesIds.filter(key => this.facilitiesMap.keys().includes(key));
  }

  changeOrderHandler(): void {
    this.setViewOrderByBuildingAndFloor({
      building: this.building,
      floor: this.title,
      order: this.sortedFacilitiesIds
    });
  }

  headerWidth(header: any): string {
    return header.width ? `${header.width}px` : '';
  }

  headerTitle(header: any): string {
    return header.title ? header.title : header;
  }
}
