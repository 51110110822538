import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { State, namespace } from 'vuex-class';
import { Route, NavigationGuardNext } from 'vue-router';
import { debounce, cloneDeep } from 'lodash';
import Hammer from 'hammerjs';
import { Base64 } from 'js-base64';
import store from '@/store';
import logger from '@/logger';
import Loading from '@/components/atoms/Loading.vue';
import PageDots from '@/components/molecules/PageDots.vue';
import FacilitiesTable from '@/components/organisms/FacilitiesTable.vue';
import ExitPublicSignageModal from '@/components/molecules/ExitPublicSignageModal.vue';
import { UiFacility } from '@/models/facility';
import { makeFacilitySearchQueryFromUrlQuery } from '@/formatters/facility';
import { View, SettingsQuery, SignageUrlQuery, FloorSettings, TenantDetails, ViewOrder, ViewOrderFloors } from '@/store/types/modules';
import BuildingsDropdown from '@/components/molecules/BuildingsDropdown.vue';
import { displayError, displayWarn } from '@/helpers/message';
import FacilityHelper, { divideByFloorsAndPaginate, FloorPage } from '@/helpers/facility';


const view = namespace('view');

@Component({
  components: { Loading, FacilitiesTable, ExitPublicSignageModal, BuildingsDropdown, PageDots },
})
export default class FacilitiesSignage extends Vue {
  @Prop({ type: String }) settings!: string;

  @Prop({ type: [String, Array] }) building!: string[];

  @Prop({ type: [String, Array] }) floor!: string[];

  @Prop({ type: String }) sort!: string;

  @State('view') view!: View;

  @view.State('settingsQuery') settingsQuery!: SettingsQuery;

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  @view.State('viewsOrder') viewsOrder!: ViewOrder[];

  @view.Action('setPublicDisplayView') viewSetPublicDisplayView!: (isPublicDisplayView: boolean) => void;

  @view.Mutation('setSettingsQuery') setSettingsQuery!: (v: SettingsQuery) => void;

  @view.Mutation('setSettingsCurrent') setSettingsCurrent!: (v: string) => void;

  // The Action automatically goes to fullscreen but the mutation doesn't
  @view.Action('setPublicDisplayView') viewSetPublicDisplayViewAction!: (isPublicDisplayView: boolean) => void;

  @view.Mutation('setPublicDisplayView') viewSetPublicDisplayViewMutation!: (isPublicDisplayView: boolean) => void;

  @State('tenant') tenant!: TenantDetails;

  private currentPage = 0;

  private defaultPageSize = 100;

  private windowWidth = 0;

  private windowHeight = 0;

  private mountIntervalID = 0;

  private moveToNextPageIntervalID = 0;

  private rowsAnimationDirection: 'left' | 'right' = 'right';

  private automaticTransition = true;

  private resetAutomaticTransitionTimeoutID = 0;

  private debounceTime = 100;

  private debouncedUpdatePageSize = debounce(this.updatePageSize, this.debounceTime);

  private debouncedHandlePan = debounce(this.handlePan, this.debounceTime);

  private debouncedHandleKeyup = debounce(this.handleKeyup, this.debounceTime);

  private hammertime = new Hammer(document.documentElement);

  private tenriVersion = process.env.VUE_APP_VERSION;

  isLoading = true;

  floors: string[] = [];

  buildings: string[] = [];

  facilities: UiFacility[] = [];

  model: SignageUrlQuery = {
    buildings: [],
    floors: [],
    publicAccess: false,
    sort: 'name:asc',
    current: '',
  };

  get tenantId(): string {
    return this.tenant.current.id;
  }

  get facilitiesTableHeaders(): Array<Record<string, any> | string> {
    return [
      { title: this.$gettext('Facility'), width: 322 },
      { title: this.$gettext('Status'), width: 130 },
      this.$gettext('Current Event'),
      this.$gettext('Next Event'),
    ];
  }

  get showDropdown(): boolean {
    return this.buildings.length > 1 && !!this.defaultCurrentBuilding && !this.isPublicDisplayView;
  }

  get facilityHelper(): FacilityHelper {
    return new FacilityHelper(this);
  }

  get pageSize(): number {
    return this.isPublicDisplayView
      ? this.amountOfRowsThatFit
      : this.defaultPageSize;
  }

  get viewOrderByBuilding(): ViewOrderFloors[] {
    const viewBuilding: ViewOrder | undefined = this.viewsOrder.find(v => v.building === this.model.current);
    return viewBuilding ? viewBuilding.floors : [];
  }

  get dividedFacilities(): FloorPage[] {
    return divideByFloorsAndPaginate(this.facilities, this.pageSize);
  }

  get animateFacilitiesTable(): boolean {
    return this.isPublicDisplayView && this.automaticTransition;
  }

  get rowsAnimationDelay(): number {
    return this.automaticTransition ? 0.7 : 0;
  }

  get runningOnMobile(): boolean {
    return this.windowWidth < 767;
  }

  get hotjarContainer(): HTMLElement | null {
    return document.getElementById('_hj_feedback_container');
  }

  get defaultCurrentBuilding(): string {
    const [firstBuilding] = this.buildings;
    return firstBuilding || '';
  }

  get decodedSettings(): SignageUrlQuery | null {
    if (this.settings) {
      try {
        const decodedQuery = Base64.decode(this.settings);
        const settings: SignageUrlQuery = JSON.parse(decodedQuery);
        return settings || null;
      } catch(e) {
        displayWarn(
          this.$gettext('Invalid query.'),
        );
        return null;
      }
    }
    return null;
  }

  async mounted(): Promise<void> {
    this.updatePageSize();
    await this.mount();
    this.currentPage = 0;

    if (this.isPublicDisplayView) {
      this.onIsPublicDisplayViewChanged(true);
    };

    if (localStorage.getItem('publicMode')) {
      localStorage.removeItem('publicMode');
      this.viewSetPublicDisplayViewMutation(true);
    } else if (localStorage.getItem('publicModeFullScreen')) {
      localStorage.removeItem('publicModeFullScreen');
      this.viewSetPublicDisplayViewAction(true);
    }

    const secs = 1000;
    const mountIntervalDuration = 60 * secs;
    this.mountIntervalID = window.setInterval(
      this.fetchFacilities,
      mountIntervalDuration,
    );
  }


  @Watch('isPublicDisplayView')
  async onIsPublicDisplayViewChanged(newIsPublicDisplayView: boolean): Promise<void> {
    if (newIsPublicDisplayView) {
      await this.enterPublicMode();
      this.setHotjarOpacity('hidden');
    } else {
      this.exitPublicMode();
      this.setHotjarOpacity('visible');
    }
  }

  setHotjarOpacity(value: string): void {
    if (this.hotjarContainer) {
      this.hotjarContainer.style.visibility = value;
    }
  }

  async beforeDestroy(): Promise<void> {
    window.clearInterval(this.mountIntervalID);
    this.exitPublicMode();
  }

  @Watch('settings')
  settingsUpdateHandler(): void {
    this.mount();
  }

  @Watch('tenant.current.id')
  async tenantUpdateHandler(): Promise<void> {
    if (this.settings) {
      await this.$router.push({
        query: {},
      });
    } else {
      this.mount();
    }
  }

  async enterPublicMode(): Promise<void> {
    this.isLoading = true;
    this.currentPage = 0;
    this.updatePageSize();
    this.sortFacilities();
    window.addEventListener('resize', this.debouncedUpdatePageSize);
    window.addEventListener('keyup', this.debouncedHandleKeyup);
    this.hammertime.on('pan', this.debouncedHandlePan);

    // User can exit the Public Signage Mode by exiting fullscreen (probably by pressing Esc)
    document.addEventListener('fullscreenchange', this.onFullScreenChangeHandler);
    document.addEventListener('mozfullscreenchange', this.onFullScreenChangeHandler);
    document.addEventListener('MSFullscreenChange', this.onFullScreenChangeHandler);
    document.addEventListener('webkitfullscreenchange', this.onFullScreenChangeHandler);
    this.setAutoPagination();
    this.isLoading = false;
  }

  setAutoPagination(): void {
    const secs = 4000;
    this.moveToNextPageIntervalID = window.setTimeout(
      () => this.movePage('next'),
      Math.max(this.dividedFacilities[this.currentPage].facilities.length * secs, 10000),
    );
  }

  exitPublicMode(): void {
    window.removeEventListener('resize', this.debouncedUpdatePageSize);
    window.removeEventListener('keyup', this.debouncedHandleKeyup);
    this.hammertime.off('pan', this.debouncedHandlePan);

    document.removeEventListener('fullscreenchange', this.onFullScreenChangeHandler);
    document.removeEventListener('mozfullscreenchange', this.onFullScreenChangeHandler);
    document.removeEventListener('MSFullscreenChange', this.onFullScreenChangeHandler);
    document.removeEventListener('webkitfullscreenchange', this.onFullScreenChangeHandler);

    window.clearInterval(this.moveToNextPageIntervalID);
  }

  onFullScreenChangeHandler(): void {
    const doc: any = document;
    if (
      !(
        doc.fullscreenElement ||
        doc.webkitIsFullScreen ||
        doc.mozFullScreen ||
        doc.msFullscreenElement
      )
    ) {
      this.viewSetPublicDisplayView(false);
    }
  };

  async mount(): Promise<void> {
    this.isLoading = true;
    await this.init();
    await this.fetchFacilities();
    this.isLoading = false;
  }

  async init(): Promise<void> {
    if (this.decodedSettings) {
      this.buildings = this.decodedSettings.buildings?.length > 0 ? this.decodedSettings.buildings : await this.fetchAllBuildings();
      this.floors = this.setFloorsArray(this.decodedSettings.floors);
      this.model = this.decodedSettings;
      this.model.current = this.decodedSettings.current ? this.decodedSettings.current : this.defaultCurrentBuilding;
    } else if (this.settingsQuery?.facilitiesSignage) {
      this.buildings = this.settingsQuery.facilitiesSignage.buildings?.length > 0 ? this.settingsQuery.facilitiesSignage.buildings : await this.fetchAllBuildings();
      this.floors = this.setFloorsArray(this.settingsQuery.facilitiesSignage.floors);
      this.model = cloneDeep(this.settingsQuery.facilitiesSignage);
      this.model.current = this.settingsQuery.facilitiesSignage.current ? this.settingsQuery.facilitiesSignage.current : this.defaultCurrentBuilding;
    } else {
      this.buildings = await this.fetchAllBuildings();
      this.model.current = this.defaultCurrentBuilding;
      this.floors = [];
      this.model.publicAccess = false;
      this.model.sort = 'name:asc';
    }
  }

  setFloorsArray(floors: FloorSettings[]): string[] {
    let res: string[] = [];
    if (floors) {
      floors.forEach((el: any) => {
        if (el.floors) {
          res = this.mergeFloors(res, el.floors);
        }
      });
    }
    return res;
  }

  beforeRouteEnter(to: Route, from: Route , next: NavigationGuardNext): void {
    store.dispatch('view/setPublicDisplayView', false);
    next();
  };

  async fetchFacilities(): Promise<void> {
    const query = makeFacilitySearchQueryFromUrlQuery({
      floor: this.floors,
      building: this.model.current ? [this.model.current] : [],
    });

    try {
      const fs = await this.facilityHelper.fetchFacilities(this.tenantId, query, this.model.sort);
      this.facilities = fs ? [...fs] : [];
    } catch (e) {
      logger.error('Failed to get facility information.', e);
      displayError(
        this.$gettext('Failed to get facility information.'),
      );
    }
  }

  async fetchAllBuildings(): Promise<string[]> {
    try {
      const fs = await this.facilityHelper.fetchFacilities(this.tenantId);
      const facilities = fs ? [...fs] : [];
      return this.facilityHelper.buildings(facilities);
    } catch (e) {
      logger.error(e);
      return [];
    }
  }

  async updateQuery(): Promise<void> {
    this.setSettingsQuery({ facilitiesSignage: this.model });
    const encodedQuery = Base64.encode(JSON.stringify(this.model));

    this.$router.push({
      name: 'facilitiesSignage',
      query: { settings: encodedQuery },
    });
  }

  async menuChangeHandler(item: string): Promise<void> {
    this.isLoading = true;
    this.model.current = item;

    await this.fetchFacilities();
    if (this.decodedSettings) {
      await this.updateQuery();
    } else {
      this.setSettingsCurrent(item);
    }

    this.isLoading = false;
  }

  mergeFloors(...arrays: Array<string[]>): string[] {
    let jointArray: string[] = [];

    arrays.forEach(array => {
      jointArray = [...jointArray, ...array];
    });
    const uniqueArray = jointArray.reduce((newArray: string[], item: string) => {
      if (newArray.includes(item)){
        return newArray;
      }
      return [...newArray, item];
    }, []);
    return uniqueArray;
  }

  sortFacilities(): void {
    this.viewOrderByBuilding.forEach((v: ViewOrderFloors) => {
      this.facilities.sort((a: UiFacility, b: UiFacility): number => v.order.indexOf(a.facility_id) - v.order.indexOf(b.facility_id));
    });
  }

  get amountOfRowsThatFit(): number {
    let headerSize = 72;
    const tableTitle = 104;
    let tableHeaders = 47;
    let rowHeight = 96;
    let reservedSpaceForPageDots = 68;

    if (this.runningOnMobile) {
      headerSize = 64;
      tableHeaders = 0;
      reservedSpaceForPageDots = 70;
      rowHeight = 206;
    }

    const extraSpace = headerSize + tableTitle + tableHeaders + reservedSpaceForPageDots;
    const usableHeight = this.windowHeight - extraSpace;
    return Math.floor(usableHeight / rowHeight);
  };

  updatePageSize(): void {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;
  }

  async movePage(direction: 'previous' | 'next'): Promise<void> {
    this.rowsAnimationDirection = direction === 'next' ? 'right' : 'left';

    const newPage = direction === 'next'
      ? (this.currentPage + 1)
      : (this.currentPage - 1);
    const { length } = this.dividedFacilities;
    this.currentPage = ((newPage % length) + length) % length;
    window.clearInterval(this.moveToNextPageIntervalID);
    this.setAutoPagination();
  }

  manualMovePage(direction: 'previous' | 'next'): void {
    this.automaticTransition = false;
    window.clearTimeout(this.resetAutomaticTransitionTimeoutID);
    this.resetAutomaticTransitionTimeoutID = window.setTimeout( () => {
      this.automaticTransition = true;
    }, 2000);
    this.movePage(direction);
  }

  async handlePan(input: HammerInput): Promise<void> {
    if (input.direction === Hammer.DIRECTION_LEFT) {
      this.manualMovePage('next');
    } else if (input.direction === Hammer.DIRECTION_RIGHT) {
      this.manualMovePage('previous');
    }
  }

  async handleKeyup(input: KeyboardEvent): Promise<void> {
    if (input.key === 'ArrowRight') {
      this.manualMovePage('next');
    } else if (input.key === 'ArrowLeft') {
      this.manualMovePage('previous');
    }
  }
}
