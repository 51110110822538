import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import FacilitySignageEvent from '@/components/molecules/FacilitySignageEvent.vue';
import FacilityAvailableIcon from '@/assets/images/icon-facility-available.svg';
import FacilityUnavailableIcon from '@/assets/images/icon-facility-using.svg';
import FacilityNotInUseIcon from '@/assets/images/icon-facility-not-using.svg';
import FacilityNoReservationIcon from '@/assets/images/icon-facility-ghost.svg';
import CircleWarningIcon from '@/assets/images/icon-circle-warning.svg';
import ArrowsIcon from '@/assets/images/arrows-v.svg';
import { UiFacility, FacilityStatusNameEnum } from '@/models/facility';
import Marquee from '@/components/atoms/Marquee.vue';
import { formatDateString } from '@/date';

const view = namespace('view');

@Component({
  components: {
    FacilitySignageEvent,
    FacilityAvailableIcon,
    FacilityUnavailableIcon,
    FacilityNoReservationIcon,
    FacilityNotInUseIcon,
    CircleWarningIcon,
    ArrowsIcon,
    Marquee
  },
})
export default class FacilitySignageRow extends Vue {
  @Prop({ type: Object, default: () => ({}) }) private facility!: UiFacility;

  @Prop({ type: Boolean, default: false }) private publicAccess!: boolean;

  @Prop({ type: Number, required: true }) private rowNumber!: number;

  @Prop({ type: String, default: 'right' }) animationDirection!: 'left' | 'right';

  @Prop({ type: Number, required: true }) animationDelay!: number;

  @Prop({ type: Boolean, required:true }) private runningOnMobile!: boolean;
  
  @Prop({ type: Boolean, required:true }) private isDragDisabled!: boolean;

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  get facilityName(): string {
    return this.facility.facility_name
      ? this.facility.facility_name
      : this.$gettext('Unknown Facility');
  }

  get transitionDelay(): string {
    return `${this.animationDelay + this.rowNumber / 16  }s`;
  }

  get facilityStatus(): string {
    switch (this.facility.status_name) {
      case FacilityStatusNameEnum.Vacant:
        return this.$gettext('Available');
      case FacilityStatusNameEnum.NoReservation:
        return this.$gettext('Unbooked Usage');
      case FacilityStatusNameEnum.NoAttendees:
        return this.$gettext('Not in Use');
      case FacilityStatusNameEnum.Occupied:
        return this.$gettext('Booked');
      default:
        return this.$gettext('Unknown Status');
    }
  }

  get transitionName(): string {
    return this.isPublicDisplayView ? `Facility-animation--${this.animationDirection}` : '';
  }

  get facilityStatusIcon(): string {
    if(this.facility.status_name === FacilityStatusNameEnum.Vacant) {
      return 'facility-available-icon';
    }
    if (this.facility.status_name === FacilityStatusNameEnum.Occupied) {
      return 'facility-unavailable-icon';
    }
    if (this.facility.status_name === FacilityStatusNameEnum.NoAttendees) {
      return 'facility-not-in-use-icon';
    }
    return 'facility-no-reservation-icon';
  }

  get marqueeOrSpan(): string {
    return this.runningOnMobile ? 'span' : 'marquee';
  }

  formatDateString(dateString: string, formattingRule: string): string {
    return formatDateString(dateString, formattingRule);
  }
}
