import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Watch } from 'vue-property-decorator';
import Hammer from 'hammerjs';
import { hasFullscreenElem } from '@/store/view';

const view = namespace('view');

@Component
export default class ExitPublicSignageModal extends Vue {
  isShown = false;

  playFadeOut = false;

  timeoutHandler: number | undefined = undefined;

  recognizers: Recognizer[] = [new Hammer.Tap({ taps: 2 })];

  mc = new Hammer(document.documentElement, { recognizers: [] });

  isInFullscreen = false;

  @view.State('isPublicDisplayView') isPublicDisplayView!: boolean;

  @view.Action('setPublicDisplayView') viewSetPublicDisplayView!: (
    isPublicDisplayView: boolean,
  ) => void;

  onHereClicked(): void {
    this.viewSetPublicDisplayView(false);
  }

  @Watch('isPublicDisplayView')
  onIsPublicDisplayViewChanged(newIsPublicDisplayView: boolean): void {
    if (newIsPublicDisplayView) {
      this.mc.add(this.recognizers);
      window.addEventListener('dblclick', this.onMouseDBLlicked);
    } else {
      this.mc.remove('tap');
      window.removeEventListener('dblclick', this.onMouseDBLlicked);
      this.hide();
    }
  }

  onMouseDBLlicked(): void {
    this.show();
  }

  show(): void {
    this.isInFullscreen = hasFullscreenElem();
    this.isShown = true;
    if (this.timeoutHandler) {
      window.clearTimeout(this.timeoutHandler);
    }
    this.timeoutHandler = window.setTimeout((): void => {
      this.hide();
    }, 3000);
  }

  hide(): void {
    this.isShown = false;
  }

  get isMobile(): boolean {
    const windowWidth = document.documentElement.clientWidth;
    if (windowWidth <= 767) {
      return true;
    }
    if (windowWidth > 1366) {
      return false;
    }
    const ua = navigator.userAgent;
    const isTabletUA = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua);
    const isMobileUA = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua,
    );
    if (isTabletUA || isMobileUA) {
      return true;
    }
    return false;
  }

  mounted(): void {
    /* This only specifies the event handler but the event listener itself
    (called a recognizer in hammerjs's terminology) is added and removed according
    to the isPublicDisplayView state. */
    this.mc.on('tap', () => {
      this.show();
    });

    if (this.isPublicDisplayView) {
      this.mc.add(this.recognizers);
      window.addEventListener('dblclick', this.onMouseDBLlicked);
    }
  }
}
