import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import Marquee from '@/components/atoms/Marquee.vue';
import OrganizerIcon from '@/assets/images/icon-organizer.svg';
import IconArrowTriangle from '@/assets/images/arrow-triangle.svg';
import { Event } from '@/api/models';
import { isToday, formatDate } from '@/date';
import { FacilityStatusNameEnum } from '@/models/facility';

const eventTypeValidator = (value: string): boolean => {
  return value === 'current' || value === 'next';
};

const statusNameValidator = (value: string): boolean => {
  return (Object.values(FacilityStatusNameEnum) as string[]).includes(value);
};

@Component({
  components: {
    Marquee,
    OrganizerIcon,
    IconArrowTriangle,
  },
})
export default class FacilitySignageEvent extends Vue {
  @Prop({ type: String, required:true, validator: eventTypeValidator }) private eventType!: string;

  @Prop({ type: Boolean, default: false }) private publicAccess!: boolean;

  @Prop({ type: Object }) private event!: Event;

  @Prop({ type: String, default:"vacant", validator: statusNameValidator }) private statusName!: string;

  @Prop({ type: Boolean, required:true }) private runningOnMobile!: boolean;

  get showNextEvent(): boolean {
    return !!this.event.start_time && isToday(this.event.start_time.toDateString());
  }

  get showCurrentEvent(): boolean {
    return this.statusName === FacilityStatusNameEnum.NoAttendees || this.statusName === FacilityStatusNameEnum.Occupied;
  }

  get marqueeOrDiv(): string {
    return this.runningOnMobile ? 'marquee' : 'div';
  }

  get marqueeOrSpan(): string {
    return this.runningOnMobile ? 'span' : 'marquee';
  }

  get showEvent(): boolean {
    if (!this.event) {
      return false;
    }

    if (this.eventType === 'current') {
      return this.showCurrentEvent;
    }
    return this.showNextEvent;
  }

  get eventTypeText(): string {
    return this.eventType === 'current' ? this.$gettext('Current Event') : this.$gettext('Next Event');
  }

  get noEventText(): string {
    if (this.eventType === 'current') {
      return this.$gettext('No current event');
    }
    return this.$gettext('No upcoming events today');
  }

  get organizerName(): string {
    if (!this.event?.organizer) {
      return this.$gettext('Unknown Organizer');
    }
    return this.event.organizer?.display_name || this.event.organizer.email;
  }

  get startTime(): string {
    return this.event ? formatDate(this.event.start_time, 'HH:mm') : '';
  }

  get endTime(): string {
    return this.event ? formatDate(this.event.end_time, 'HH:mm') : '';
  }
}
