import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import kebabCase from 'lodash/kebabCase';
import Dropdown from '@/components/molecules/Dropdown.vue';
import DownArrowIcon from '@/assets/images/icon-arrow-down.svg';
import SuccessIcon from '@/assets/images/success.svg';

@Component({
  components: {
    Dropdown,
    DownArrowIcon,
    SuccessIcon,
  },
})
export default class BuildingsDropdown extends Vue {
  @Prop({ type: Array, required: true }) private buildings!: string[];

  @Prop({ type: String, required: true }) private current!: string;

  @Prop({ type: Boolean, required: false, default: false }) private strongArrow!: boolean;

  @Prop({ type: Boolean, required: false, default: false }) private inline!: boolean;

  @Prop({ type: String, default: 'start' }) private align!: 'start' | 'center' | 'end';

  async menuClickHandler(item: string): Promise<void> {
    this.$emit('change', item);
  }

  kebabCase(s: string): string {
    return kebabCase(s);
  }
}
