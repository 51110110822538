import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class PageDots extends Vue {
  private maxPages = 10;

  @Prop({ type: Number, required: true }) total!: number;

  @Prop({ type: Number, required: true }) actual!: number;

  get numberOfPages(): number {
    return Math.min(this.total, this.maxPages);
  }
}
