var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Buildings-dropdown",class:{'Buildings-dropdown--inline': _vm.inline}},[_c('dropdown',{attrs:{"id":"js-building-dropdown","position":"down","align":_vm.align,"items":_vm.buildings},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('label',{staticClass:"Buildings-dropdown__button js-buildings-menu-button",class:{'Buildings-dropdown__button--inline': _vm.inline}},[_vm._v(" "+_vm._s(_vm.current)+" "),_c('down-arrow-icon',{class:{ 'DownArrow--strong': _vm.strongArrow }})],1)]},proxy:true},{key:"item",fn:function(ref){
var mItem = ref.item;
var close = ref.closeDropdown;
return [_c('div',{staticClass:"Buildings-dropdown__item js-buildings-menu-contents",class:{
          'FacilitiesSignage__navi-item--active Buildings-dropdown__item--active':
            mItem === _vm.current,
        },on:{"click":function () {
            _vm.menuClickHandler(mItem);
            close();
          }}},[_c('button',{staticClass:"Buildings-dropdown__building",attrs:{"id":("js-" + (_vm.kebabCase(mItem)) + "-buildings-menu"),"type":"button","active-class":"js-buildings-menu-active"}},[_c('span',[_vm._v(_vm._s(mItem))]),(mItem === _vm.current)?_c('success-icon',{staticClass:"Buildings-dropdown__success-icon"}):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }